import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';

import systems from '../config/systems';
import * as ConfigAPI from '../config/api';
import { parseDateForReq } from '../helpers/dateHelpers';
import { IBookingDataReq, TRestaurant } from '../types/IRootWidgetStore';
import {
  getAccessToken,
  getRefreshToken,
  updateToken,
} from '../helpers/localStorageHelpers';

const api = axios.create({ withCredentials: true });

/**
 * Запрос на получение обновление токена
 * @param refreshToken
 * @returns {Promise<AxiosResponse<T>>}
 */
export const refreshTokenReq = (refreshToken: string) => {
  const userData = `refresh_token=${refreshToken}&grant_type=refresh_token`;
  const base64BasicKey = btoa(`${systems.clientId}:${systems.secretKey}`);

  return api.post(
    ConfigAPI.token, userData, { headers: { Authorization: `Basic ${base64BasicKey}` } },
  );
};

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const accessToken = getAccessToken();
  const configResult = { ...config };

  if (accessToken && accessToken !== 'undefined' && config.url !== ConfigAPI.token) {
    configResult.headers.Authorization = `Bearer ${accessToken}`;
  }
  return configResult;
});

api.interceptors.response.use(response => response, (error) => {
  const originalRequest = error.config;

  if (error.response && error.response.status === 401 && originalRequest.url === ConfigAPI.token) {
    return Promise.reject(error);
  }

  if (error.response && error.response.status === 401 && !originalRequest._retry) { // eslint-disable-line no-underscore-dangle
    originalRequest._retry = true; // eslint-disable-line no-underscore-dangle
    const refreshToken = getRefreshToken();

    if (refreshToken) {
      return refreshTokenReq(refreshToken).then((res: {
        status: number;
        data: {
          access_token: string;
          refresh_token: string;
        }
      }) => {
        if (res.status === 200) {
          updateToken(res.data.access_token, res.data.refresh_token);
          originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`;

          return axios(originalRequest);
        }
        return null;
      });
    }
    return null;
  }
  return Promise.reject(error);
});

/**
 * Запрос на получение количества столов для резерва по выбранным параметрам
 * @param widgetId
 * @param date - дата
 * @param persons - количество персон
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getTablesCountReq = (widgetId: number, date: string, persons: number) => api
  .get(`${ConfigAPI.restaurantTable}/${widgetId}/free/count`, {
    params: {
      time: parseDateForReq(date),
      persons,
    },
  }).then(r => r.data.count);

/**
 * Отправить запрос на отправку кода подтверждения
 * @param phone - Номер телефона
 * @param widgetId - Id виджета
 */
export const sendConfirmCodeReq = (phone: string, widgetId: number) => api.post((`${ConfigAPI.restaurantWidget}/${widgetId}/confirmation/code/request`), {
  phone,
});

/**
 * Проверка кода подтверждения
 * @param code
 * @param phone
 */
export const verifySmsCodeReq = (code: string, phone: string) => {
  const base64BasicKey = btoa(`${systems.clientId}:${systems.secretKey}`);
  const data = new URLSearchParams({
    phone,
    code,
    grant_type: 'sms_code',
  })

  return api
    .post(ConfigAPI.token, data, { headers: { Authorization: `Basic ${base64BasicKey}` } })
    .then(
      response => response.data,
      error => error.response.data,
    );
};

/**
 * Резервирования для виджета
 * @param bookingData
 */
export const guestBookingReq = (bookingData: IBookingDataReq) => {
  const {
    widgetId,
    comment,
    persons,
    date,
    fullName,
    smsNotificationIsEnabled,
    phone,
  } = bookingData;
  const parsedName = fullName.split(' ');
  return api
    .post(ConfigAPI.widgetBooking, {
      widgetId,
      comment,
      persons,
      date: parseDateForReq(date),
      firstName: parsedName[0],
      lastName: parsedName.splice(1).join(" "),
      isNotificationEnabled: smsNotificationIsEnabled,
      phone,
    })
    .then(res => res.data);
};

/**
 * Загрузка данных по ресторану
 * @param {number} widgetId
 */
export const getRestaurantInfo = (widgetId: number): Promise<TRestaurant> => api
  .get(`${ConfigAPI.restaurantWidget}/${widgetId}/info`)
  .then(res => res.data);

/**
 * Проверка статуса бронирования
 * @param bookingId
 */
export const bookingStatus = (bookingId: number) => api
  .get(`${ConfigAPI.widgetBooking}/${bookingId}/status`)
  .then(res => res.data);

/** *
 * Запрос информации по брони, для кейсов когда у юзера есть активная бронь в этом ресторане
 */
export const getActiveBookingInfo = (bookingId: number) => api
  .get(`${ConfigAPI.widgetBooking}/${bookingId}`)
  .then(res => res.data);

/**
 * Дроп бронирования
 * @param bookingId
 */
export const deleteBooking = (bookingId: number) => api
  .delete(`${ConfigAPI.widgetBooking}/${bookingId}`);

/**
 * Загрузка расписания ресторана по id и дате c исклюючениями
 * @param widgetId
 * @param date
 */
export const getRestaurantScheduleReq = (
  widgetId: number, date: string,
) => api.get(`${ConfigAPI.restaurantSchedule}/${widgetId}`, {
  params: {
    date: moment(date).format('YYYY-MM-DD'),
  },
}).then(res => res.data);

/**
 * Запрос на получение максимальной вместимости столов
 * @param widgetId
 */
export const getMaxPersonCountReq = (widgetId: number) => api
  .get(`${ConfigAPI.restaurantTable}/${widgetId}/capacity/maximum`)
  .then(r => r.data.maxCapacity);
