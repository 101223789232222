import {
  put, all, takeLatest, delay, call, select, fork,
} from 'redux-saga/effects';
import _ from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router';
import * as Sentry from '@sentry/react';

import {
  verifySmsCodeReq,
  guestBookingReq,
  bookingStatus,
  getRestaurantInfo,
  deleteBooking,
  sendConfirmCodeReq,
  getActiveBookingInfo,
} from '../api';
import * as apiUtils from '../api/utils'
import * as ReserveWidgetActionsTypes
  from '../actions/rootWidget/RootWidgetActionsTypes';
import * as ReserveWidgetActions from '../actions/rootWidget/RootWidgetActions';
import * as IReserveActions from '../actions/rootWidget/IRootWidgetActions';
import * as ReserveFormActions
  from '../actions/reserveForm/ReserveFormActions';

import locales from '../locales';
import {
  setWidgetData,
  setTokens,
  getWidgetData,
  clearWidgetData
} from '../helpers/localStorageHelpers';
import { IAppStore } from '../types/IAppStore';
import { errorType, reserveWidgetState } from '../config/constants';
import { IGetRestaurantInfo } from '../actions/rootWidget/IRootWidgetActions';
import { fallbackValue } from '../helpers/constants';

const BOOKING_STATUS_ACCEPTED = 'ACCEPTED';
const BOOKING_STATUS_ERROR = 'ERROR';
const BOOKING_STATUS_DECLINED = 'DECLINED';
const BOOKING_STATUS_CANCELED = 'CANCELED';
const BOOKING_STATUS_CREATED = 'CREATED';
const BOOKING_SENT = 'SENT';

const BOOKING_STATUS_REQUEST_DELAY = 5000;
const INCORRECT_CODE_CODE = 'code_incorrect';
const BOOKING_NOT_CLOSED = 'guest_have_not_closed_booking';

/**
 * @description Trigger data fetching, since main component wont be rendered
 * until restaurant data initialized
 */
function* init() {
  const route = yield select((state: IAppStore) => state.router.location);
  const matched = matchPath(route.pathname, {
    path: '/:key',
    strict: false
  })
  if (matched) {
    // @ts-ignore
    const { key }: { key: string | null } = (matched.params || { key: null });
    const widgetId = typeof key === 'string' ? Number(key) : null;
    if (widgetId) {
      yield put(ReserveWidgetActions.getRestaurantInfo(widgetId));
      yield put(ReserveFormActions.getMaxPersonCount(widgetId));
    }
  }
}

/**
 * @description watch widget status,
 */
function* watchStatus(widgetData: any) {
  if (widgetData) {
    try {
      const state = yield call(bookingStatus, widgetData.reserveId);

      if (state.status === BOOKING_STATUS_CREATED || state.status === BOOKING_SENT) {
        yield put(ReserveWidgetActions.initAppWidgetState(
          widgetData.userData,
          reserveWidgetState.PROCESSING_STATE
        ));

        // blocks execution until resolved
        const isApproved = yield call(approveReserveStatusSaga, widgetData.reserveId);

        if (isApproved) {
          yield put(ReserveWidgetActions.guestBookingSuccess());
        }
      }

      if (state.status === BOOKING_STATUS_ACCEPTED) {
        yield put(ReserveWidgetActions.initAppWidgetState(widgetData.userData));
        yield put(ReserveWidgetActions.guestBookingSuccess());
      }

      if (state.status === BOOKING_STATUS_CANCELED) {
        clearWidgetData();
      }
    } catch (e) {
      Sentry.captureException(e);
      clearWidgetData();
    }
  }
}

/** @description Сага для инита виджета */
function* initApp(action: IGetRestaurantInfo) {
  const widgetData = getWidgetData();

  yield fork(watchStatus, widgetData);

  try {
    const restaurantInfo = yield apiUtils.queryWithRetry(getRestaurantInfo, action.payload.widgetId);
    const { address } = restaurantInfo || { address: {} };
    const addressString = `${address.city}, ${address.street}, ${address.home}`;
    const restaurantName = restaurantInfo ? restaurantInfo.name : 'Рестаран';
    const settings = restaurantInfo ? restaurantInfo.settings : null;
    const { phone } = restaurantInfo ? restaurantInfo.info : '';

    yield put(ReserveWidgetActions.initAppRestaurantInfo({
      address: addressString,
      name: restaurantName,
      phone,
      countryCode: address.countryCode,
      settings: Object.assign({}, fallbackValue.settings, settings)
    }));

    Sentry.setContext('restaurant', {
      widgetId: action.payload.widgetId,
      address: addressString,
      name: restaurantName,
      phone,
    });
  } catch (e) {
    Sentry.captureException(e);
    console.log('initApp', e.message);
  }
}

/** @description Сага для запроса кода подтверждения*/
function* sendConfirmationCodeSaga() {
  try {
    const { phone } = yield select((state: IAppStore) => state.root.bookingReqData);
    const { countryCode } = yield select((state: IAppStore) => state.root.restaurantInfo);
    const widgetId = yield select((state: IAppStore) => state.root.widgetId);

    const confirmReqData = yield sendConfirmCodeReq(phone, widgetId);
    yield put(ReserveWidgetActions.sendRegistrationRequestSuccess(confirmReqData.data.timeLeft));

    if(countryCode !== 'RU'){
      try {
        yield put(ReserveWidgetActions.verifySmsCodeSuccess());
      } catch (e) {
        Sentry.captureException(e);
        yield put(ReserveWidgetActions.verifySmsCodeError(locales.t('common.errors.reserveWidget.smsCodeError')));
      }
    }

  } catch (e) {
    Sentry.captureException(e);

    let error = locales.t('common.errors.reserveWidget.unknownError');

    if (!_.isUndefined(e.response.data[0]) && e.response.data[0].code === 'mobile_phone_is_incorrect') {
      error = locales.t('common.errors.reserveWidget.phoneParseError');
    }
    yield put(ReserveWidgetActions.sendRegistrationRequestError());
  }
}

/** @description Сага для подтверждения кода из смс */
function* verifyConfirmationCodeSaga(action: IReserveActions.ISendSmsCode) {
  try {
    const { phone } = yield select((state: IAppStore) => state.root.bookingReqData);
    const { smsCode } = action.payload;
    const verifyReqData = yield verifySmsCodeReq(smsCode, phone);

    if (!verifyReqData || verifyReqData.error) {
      let errorMessage = locales.t('common.errors.reserveWidget.smsCodeError');

      if (!verifyReqData || verifyReqData.error === INCORRECT_CODE_CODE) {
        errorMessage = locales.t('common.errors.reserveWidget.pinIncorrect');
      }

      yield put(ReserveWidgetActions.verifySmsCodeError(errorMessage));
    } else {
      setTokens(verifyReqData.access_token, verifyReqData.refresh_token);

      yield put(ReserveWidgetActions.verifySmsCodeSuccess());
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveWidgetActions.verifySmsCodeError(locales.t('common.errors.reserveWidget.smsCodeError')));
  }
}

/**
 * @description Сага для проверки статуса бронирования,
 * - вызывается "bookingStatus" с интервалом "BOOKING_STATUS_REQUEST_DELAY"
 * @param bookingId
 */
function* approveReserveStatusSaga(bookingId: number) {

  yield put(ReserveWidgetActions.guestBookingQueueProcessing(bookingId));

  while (true) {
    try {
      const apiResponse = yield call(bookingStatus, bookingId);
      const { status } = apiResponse;

      if (status === BOOKING_STATUS_ACCEPTED) {
        return true;
      }
      if (status === BOOKING_STATUS_ERROR || status === BOOKING_STATUS_DECLINED) {
        yield put(ReserveWidgetActions.guestBookingError(apiResponse.status));
        return false;
      }
      if (status === BOOKING_STATUS_CANCELED) {
        return false;
      }

      yield delay(BOOKING_STATUS_REQUEST_DELAY);
    } catch (e) {
      Sentry.captureException(e);
      yield put(ReserveWidgetActions.guestBookingError(locales.t('common.errors.reserveWidget.unknownError')));
      return false;
    }
  }
}

/** @description Сага для бронирования */
function* guestBookingSaga() {
  const widgetId = yield select((state: IAppStore) => state.root.widgetId);
  const {
    persons,
    dateForReq,
    comment,
    fullName,
    smsNotificationIsEnabled,
    phone,
  } = yield select((state: IAppStore) => state.root.bookingReqData);

  try {
    const bookingInfo = yield call(guestBookingReq, {
      widgetId,
      persons,
      date: dateForReq,
      comment,
      fullName,
      smsNotificationIsEnabled,
      phone,
    });

    setWidgetData(bookingInfo.id, {
      persons,
      date: dateForReq,
      comment,
      fullName,
      smsNotificationIsEnabled,
      phone,
    });

    const isApproved = yield call(approveReserveStatusSaga, bookingInfo.id);

    if (isApproved) {
      yield put(ReserveWidgetActions.guestBookingSuccess());
    }
  } catch (e) {
    Sentry.captureException(e);

    if (e.response && e.response.data[0]) {
      const response = e.response.data[0];

      switch (response.code) {
        case BOOKING_NOT_CLOSED:
          const bookingData = yield call(getActiveBookingInfo, response.params.id);
          yield put(ReserveWidgetActions.guestBookingError(errorType.activeReserve, {
            ...bookingData,
            phone,
            fullName,
          }));
          break;
        default:
          yield put(ReserveWidgetActions.guestBookingError(errorType.unknownError));
          break;
      }

    } else yield put(ReserveWidgetActions.guestBookingError(errorType.unknownError));
  }
}

/** @description Удаление бронирования */
function* deleteReserveSaga(action: IReserveActions.IDeleteBooking) {
  try {
    yield deleteBooking(action.payload.bookingId);
    yield put(ReserveWidgetActions.deleteBookingSuccess());
    clearWidgetData();
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveWidgetActions.deleteBookingError(e));
  }
}

export default function* saga() {
  yield all([
    takeLatest(LOCATION_CHANGE, init),
    takeLatest(ReserveWidgetActionsTypes.INIT_APP, initApp),
    takeLatest([
      ReserveWidgetActionsTypes.REGISTRATION_REQUEST,
      ReserveWidgetActionsTypes.RESEND_REGISTRATION_REQUEST,
    ], sendConfirmationCodeSaga),
    takeLatest(ReserveWidgetActionsTypes.SMS_CODE_VERIFY_REQUEST, verifyConfirmationCodeSaga),
    takeLatest([ReserveWidgetActionsTypes.SMS_CODE_VERIFY_SUCCESS, ReserveWidgetActionsTypes.GUEST_BOOKING_REQUEST], guestBookingSaga),
    takeLatest(ReserveWidgetActionsTypes.DELETE_RESERVE_REQUEST, deleteReserveSaga),
  ]);
}
