import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import ReserveForm from './ReserveForm';
import { IAppStore } from '../../types/IAppStore';
import { DispatchProps, StateProps } from './IReserveForm';
import { IAppActions } from '../../types/IAppActions';
import {
  getFreeTables,
  getMaxPersonCount,
  getRestaurantSchedule,
} from '../../actions/reserveForm/ReserveFormActions';
import {guestBooking, sendRegistrationRequest} from '../../actions/rootWidget/RootWidgetActions';

function mapStateToProps(state: IAppStore): StateProps {
  return {
    freeTables: state.reserveForm.freeTables,
    freeTablesError: state.reserveForm.freeTablesError,
    maxPersons: state.reserveForm.maxPersons,
    isSecurityEnabled: state.root.restaurantInfo.settings.isSecurityEnabled,
    countryCode: state.root.restaurantInfo.countryCode,
    date: state.reserveForm.date,
    persons: state.reserveForm.persons,
    scheduleOptions: state.reserveForm.scheduleOptions,
    isLoading: state.root.bookingIsProcessing,
    bookingData: state.root.bookingReqData,
  }
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    getFreeTables,
    getMaxPersonCount,
    guestBooking,
    getRestaurantSchedule,
    sendRegistrationRequest,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveForm);

